$(document).ready(function() {
  // Move search and social into menuAdmin
  $(".headerContent .header-search")
    .appendTo(".pageHeader #menuAdmin")
    .wrapAll('<li class="menuAdminSearch" />');
  $(".headerContent .socialIcons")
    .appendTo(".pageHeader #menuAdmin");
});


$('.mainLogo').attr('href', 'https://www.horatiosgarden.org.uk/');
